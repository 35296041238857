import { isKeyHotkey } from "is-hotkey";

/**
 * Toggles the given marktype on the given hotkey
 * @param {string} hotkey The hotkey
 * @param {string} action The callback function. {event, editor, change, onChange} are passed to it
 * @returns {Object} The slate plugin
 */
export const hotkey = (hotkey, action) => ({
	/**
	 * An onKeyDown handler for hotkeys
	 * @param {Event} event The key event
   * @param {Editor} editor the editor where the hotkey was triggered
   * @param {function} next the function that is called next
   * @returns {function|void} the function that is called next
	 */
	onKeyDown(event, editor, next) {
		// Check that the key pressed matches our `key` option.
		if (!isKeyHotkey(hotkey)(event) || !editor) return next();

		// Prevent the default characters from being inserted.
		event.preventDefault();
		event.stopPropagation();

		editor.command(action);

	},
});

/**
 * Creates an array of slate plugins based on a hotkey array
 * @param {Array} hotkeys The hotkey array
 * @returns {Array} The plugin array
 */
export const createHotkeyPlugins = hotkeys =>
	hotkeys.map(({ key, action }) => hotkey(key, action));
