import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {renderBlockButton, renderNode} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { hasBlock } from "../helpers/content";
import ImageBlock from "./ImageBlock";

/**
 * Inserts an image
 */
class ImageBlockButton extends AntonButton {
  name = "image-block";
  icon = <FontAwesomeIcon icon={["far","file-image"]} />;
  tooltip = {
    target: "image-block",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Bild einfügen"
  };
  /**
   * Called when clicked
   * @param {Editor} editor the slate editor
   * @param {Object} anton a reference to the anton react component
   * @returns {void}
   */
  onMouseDown = (editor,anton) => editor.insertImage();
}



/**
 * Helper function to insert an image
 * @param {Editor} editor the slate editor
 * @param {Object} image the image
 * @returns {void}
 */
const insertImage = (editor,image) => {
  editor.setBlocks({
    type: "image",
    data: { image }
  });
};

/**
 * Deserializes an image element
 * @param {Object} element The element to deserialize
 * @param {function} next A callback function to render the children
 * @returns {Object} The deserialized data
 */
const deserializeImage = (element,next) => {
  if (element.tagName.toLowerCase() === 'img') {
    return {
      object: 'block',
      type: 'image',
      nodes: next(element.childNodes),
      data: {
        src: element.getAttribute('src'),
      },
    }
  }
};

/**
 * The Image Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Image = (options) => Object.assign(
  renderNode("image", ImageBlock),
  renderBlockButton("image",ImageBlockButton),
  {
    deserialize: deserializeImage,
    isActive: hasBlock("image"),
    commands: {
      insertImage: insertImage
    }
  }
);

export default Image;
