import React from "react";
import styled from "styled-components";
import { Popover } from "reactstrap";

import { colors } from "../../../utilities/style";
import HyperlinkEditPopover from "./HyperlinkEditPopover";


const StyledA = styled.a`
  color: ${colors.green_3};
  &&:hover {
    color: ${colors.green_4}
  }
`;

const FakeA = styled.span`
  color: ${colors.green_3};
`;

/**
 * Renders a hyperlink
 * @returns {Component} the react component
 */
class HyperlinkMark extends React.PureComponent {

  ref = null;

  constructor(props) {
    super(props);
  }

  /**
   * Sets a reference to the element
   * @param {Element} element the element that should be set as reference
   * @returns {void}
   */
  setRef = (element) => this.ref = element;

  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, attributes, node, isSelected, isFocused, serializer, editor } = this.props;
    const url = node.data.get('url');

    const A = serializer ? StyledA : FakeA;

    return (
      <React.Fragment>
        <A href={url ? url : "#"} {...attributes} innerRef={this.setRef} target="_blank">
          {children}
        </A>
        {!serializer && this.ref && <Popover target={this.ref} placement={"bottom"} isOpen={(isFocused || isSelected)}>
          <HyperlinkEditPopover editor={editor}
                                url={url}/>
        </Popover>}
      </React.Fragment>
    );
  };
}

export default HyperlinkMark;
