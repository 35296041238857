import React from "react";

import { renderNode } from "../helpers/render";
import {hasBlock} from "../helpers/content";
import {markdownBlockSequence} from "../helpers/markdown";

/**
 * Renders a list item
 */
class ListItemNode extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, attributes } = this.props;
    return <li {...attributes}>{children}</li>;
  };
}

/**
 * The List Item
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const ListItem = (options) => Object.assign(
  renderNode("list-item", ListItemNode),
  markdownBlockSequence("list-item", ["*", "-"]),
  {
    isActive: hasBlock("list-item"),
  }
);

export default ListItem;
