import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button, Form, Input, Popover} from "reactstrap";
import PropTypes from "prop-types";

/**
 * Renders a popover to edit the hyperlink
 * @returns {Component} the react component
 */
export class LatexEditPopover extends React.Component {

  /**
   * onClick handler for the changeLatex button
   * @param {Event} event the event
   * @returns {void}
   */
  changeLatex = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.changeLatex();
  };

  /**
   * onClick handler for the removeLatex button
   * @param {Event} event the event
   * @returns {void}
   */
  removeLatex = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.removeLatex();
  };




  render = () => {
    const {target, latex, onInputChange,isOpen} = this.props;
    return (
      target ? (<Popover placement={"bottom"} isOpen={isOpen} target={target} toggle={() => null}>
        <Form inline>
          <Input type="text" name="latex" id="latex" autoFocus value={latex} onChange={onInputChange} placeholder="Füge eine Formel oder eine Gleichung ein" />
          <Button size="sm" className={"mx-1"} color={"primary"} onClick={this.changeLatex}><FontAwesomeIcon icon={["far","check"]} /></Button>
          <Button size="sm" className={"mr-1"} color={"secondary"} onClick={this.removeLatex}><FontAwesomeIcon icon={["far","trash-alt"]} /></Button>
        </Form>
      </Popover>) : null
    )
  }
}

LatexEditPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  latex: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  changeLatex: PropTypes.func.isRequired,
  removeLatex: PropTypes.func.isRequired
};

export default LatexEditPopover;
