import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import styled from "styled-components";

import { colors } from "../../../utilities/style";
import ImageEditPopover from "./ImageEditPopover";
import AntonContext from "../AntonContext";

const EditorStyledImage = styled.div`
  position: relative;
  box-sizing: content-box;
  display: inline-block;
  img {
    border: 2px solid transparent;
  }

`;

const Img = styled.img`
  max-width: ${({ layout }) => (layout === "full-width" ? "100%" : "50%")};

  ${({ layout }) =>
  layout === "align-left"
    ? "float:left;"
    : layout === "align-center"
    ? "margin: 0 auto;"
    : layout === "align-right"
      ? "float:right"
      : ""};

  height: auto;

  display: block;

  &:after {
    clear: both;
  }
`;

const Clearfix = styled.div`
  :after {
    content: "";
    display: block;
    clear: both;
  }
`;


const ImageWrapper = styled.div`
  background: ${colors.grey_1};
  color: ${colors.fontLight};
  border-radius: 5px;
  display: block;
  
  ${({image}) => (image ? `background: transparent; color: ${colors.font};` : '')}
  
  ${({serializer, isFocused, isSelected}) => {
  if(!serializer && (isFocused || isSelected)) {
    return (`background: ${colors.green_1}; cursor: pointer;`)
  }
}}
  &&:hover {
    ${({serializer}) => (serializer ? '' : `background: ${colors.green_1}; cursor: pointer;`)}
  }
`;

/**
 * Renders an image
 */
class ImageBlock extends React.PureComponent {


  ref = null;

  removeImage = () => {
    const { node, editor } = this.props;
    editor.setBlocks('paragraph');
  };

  /**
   * Changes the layout of the given image
   * @param {string} layout the layout string
   * @returns {void}
   */
  changeLayout = (layout) => {
    const { node, editor } = this.props;
    editor.setNodeByKey(node.key, { data: { ...node.data.toJS(), layout: layout }});
  };

  /**
   * Inserts the given image
   * @param {Object} image the image
   * @returns {void}
   */
  changeImage = (image) => {
    const {editor} = this.props;
    editor.insertImage(image);
  };

  /**
   * Sets a reference to the element
   * @param {Element} element the element that should be set as reference
   * @returns {void}
   */
  setRef = (element) => this.ref = element;

  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { node, attributes, isSelected, serializer = false, isFocused } = this.props;

      const image = node.data.get("image");
    const imageLayout = node.data.get("layout") || "align-left";

    const img = image ?  (
      <Img
        src={image.path}
        width={image.width || ""}
        height={image.height || ""}
        alt={"Bild #" + image.id}
        layout={imageLayout}
      />
    ) : null;

    return (
      <React.Fragment>
        {serializer && image &&
          <EditorStyledImage {...attributes}>
            <Clearfix>{img}</Clearfix>
          </EditorStyledImage>
        }
        {!serializer &&
        <ImageWrapper innerRef={this.setRef} className={"my-2 p-2"} serializer={serializer} image={image} isFocused={isFocused} isSelected={isSelected}>
          {image ?
            <EditorStyledImage {...attributes}>
              <Clearfix>{img}</Clearfix>
            </EditorStyledImage>
            : <span><FontAwesomeIcon icon={["far","file-image"]} size={"lg"} className={"mr-2"}/>Füge ein Bild ein.</span>}
        </ImageWrapper>
        }
        {!serializer &&
          <AntonContext.Consumer>
            {({uploadImage}) => (
              <ImageEditPopover
                isOpen={(isSelected && isFocused)}
                target={this.ref}
                image={image}
                changeImage={this.changeImage}
                changeLayout={this.changeLayout}
                layout={imageLayout}
                removeImage={this.removeImage}
                onUpload={uploadImage}
              />
            )}
          </AntonContext.Consumer>
        }
      </React.Fragment>
    )
  };
}

export default ImageBlock;
