import styled from "styled-components";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { colors } from "../../../utilities/style";
import LatexEditPopover from "./LatexEditPopover";

const MathQuill = require("exports-loader?window.MathQuill!imports-loader?window.jQuery=jquery!mathquill/build/mathquill.js");
const MQ = MathQuill.getInterface(2);

const BlockMathContainer = styled.div`
  margin-bottom: 0.25rem;
  ${({ onClick }) => (onClick ? "cursor:pointer !important;" : "")};

  & > div {
    position: relative;
    ${({ position }) => {
  switch (position) {
    case "align-right":
      return "float:right;";
    case "align-middle":
      return "left:50%;transform: translateX(-50%);";
    case "align-left":
    default:
      return "";
  }
}};
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const LatexWrapper = styled.div`
  background: ${colors.grey_1};
  color: ${colors.fontLight};
  border-radius: 5px;
  display: block;
  
  ${({latex}) => (latex ? `background: transparent; color: ${colors.font};` : '')}
  
  ${({serializer, isFocused, isSelected}) => {
    if(!serializer && (isFocused || isSelected)) {
      return (`background: ${colors.green_1}; cursor: pointer;`)
    }  
  }}
  &&:hover {
    ${({serializer}) => (serializer ? '' : `background: ${colors.green_1}; cursor: pointer;`)}
  }
`;

/**
 * Renders a latex formula
 */
class LatexBlock extends React.PureComponent {

  ref=null;

  constructor(props) {
    super(props);
    this.state = { latex : '' };
  }

  /**
   * Sets a reference to the element
   * @param {Element} element the element that should be set as reference
   * @returns {void}
   */
  setRef = (element) => this.ref = element;

  /**
   * An onChange handler
   * @param {Event} event the event
   * @returns {void}
   */
  onChange = (event) => {
    this.setState({ latex: event.target.value });
  };

  removeLatex = () => {
    const { node, editor } = this.props;
    editor.setBlocks('paragraph');
  };

  changeLatex = () => {
    const { node, editor } = this.props;
    const { latex } = this.state;
    editor.setNodeByKey(node.key, { data: { ...node.data.toJS(), latex: latex }});
    editor.moveForward();
  };

  componentDidMount() {
    const { node } = this.props;
    this.setState({latex: node.data.get("latex") ? node.data.get("latex") : ''})
  }

  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, node, attributes, serializer = false, isSelected, isFocused } = this.props;
    const latex = serializer ? node.data.get("latex") : this.state.latex;
    const position = node.data.get("position") || "align-left";
    const parent = document.createElement("div");
    const latexContainer = document.createElement("div");
    latexContainer.appendChild(document.createTextNode(latex));
    MQ.StaticMath(latexContainer);
    parent.appendChild(latexContainer);
    return (
      <React.Fragment>
        {serializer && latex &&
          <BlockMathContainer
            position={position}
            dangerouslySetInnerHTML={{__html: parent.innerHTML}}
            {...attributes}
          />
        }
        {!serializer &&
        <LatexWrapper innerRef={this.setRef} className={"my-2 p-2"} serializer={serializer} latex={latex}
                      isFocused={isFocused} isSelected={isSelected}>
          {latex &&
          <BlockMathContainer
            position={position}
            dangerouslySetInnerHTML={{__html: parent.innerHTML}}
            {...attributes}
          />}
          {!latex &&
          <span><FontAwesomeIcon icon={["far","function"]} size={"lg"} className={"mr-2"}/>Füge eine LaTeX Formel ein.</span>
          }
        </LatexWrapper>
        }
        {!serializer &&
          <LatexEditPopover
            isOpen={(isSelected && isFocused)}
            onInputChange={this.onChange}
            target={this.ref}
            latex={latex}
            changeLatex={this.changeLatex}
            removeLatex={this.removeLatex}
          />}
        </React.Fragment>
    );
  };
}

export default LatexBlock;
