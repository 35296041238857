import Html from "slate-html-serializer";
import { Value } from "slate";

import { plugins } from "./plugins";

export const serializer = new Html({
  rules: [
    {
      /**
       * Serializes a block or inline element
       *
       * The serialize function should return a React element representing the serialized HTML,
       * or nothing if the rule in question doesn't know how to serialize the object, in which case
       * the next rule in the stack will be attempted.
       *
       * @param {Object} node The node that should be serialized
       * @param {Object} children The children attribute
       * @returns {Node} The serialized data/node
       */
      serialize({ object, type, data, nodes }, children) {
        if (object !== "block" && object !== "inline") return undefined;

        const fns =
          plugins
            .filter(plugin => plugin.renderNode)
            .map(plugin => plugin.renderNode) || [];
        let i = 0;
        let args = [];

        /**
         * Calls the next function in the stack
         * @param {Object} overrides overrides for the next function
         * @returns {function} the next function
         */
        function next(...overrides) {
          const fn = fns[i++];

          if (!fn) return undefined;

          if (overrides.length) {
            args = overrides;
          }
          return fn(...args, null, next);
        }

        return next({
          attributes: {},
          children,
          node: { object, type, data, nodes },
          serializer: true
        });
      }
    },
    {
      ...plugins
        .filter(plugin => plugin.deserialize)
        .map(plugin => plugin.deserialize),
      /**
       * Serializes a mark element
       * @param {Object} node The node that should be serialized
       * @param {Object} children The children attribute
       * @returns {Node} The serialized data/node
       */
      serialize({ object, type, data }, children) {
        if (object !== "mark") return undefined;

        const fns =
          plugins
            .filter(plugin => plugin.renderMark)
            .map(plugin => plugin.renderMark) || [];
        let i = 0;
        let args = [];

        /**
         * Calls the next function in the stack
         * @param {Object} overrides overrides for the next function
         * @returns {function} the next function
         */
        function next(...overrides) {
          const fn = fns[i++];

          if (!fn) return undefined;

          if (overrides.length) {
            args = overrides;
          }
          return fn(...args, null, next);
        }

        return next({ children, mark: { type, data } });
      }
    }
  ],
  defaultBlock: { type: "paragraph" }
});

export default serializer;

/**
 * Converts a slate json string to an html string
 * @param {string} json The json string
 * @returns {string} The html
 */
export const jsonToHtml = json => {
  try {
    return serializer.serialize(Value.fromJSON(JSON.parse(json)));
  } catch (e) {
    //console.error(e);
    return null;
  }
};

/**
 * Strips all markup and converts a slate json string to simple text
 * @param {string} json The json string
 * @returns {string} The text
 */
export const jsonToText = json => {
  return Value.fromJSON(JSON.parse(json)).document.text;
};

//
/**
 * Generates an empty document with a *unique* document key.
 * We can't use a static value because document keys have to be unique
 * @returns {Value} A slate value
 */
export const empty = () => serializer.deserialize("<p></p>");

export const emptyString = JSON.stringify(empty().toJSON());

/**
 * Checks if a string is an empty slate document
 * @param {string} val The string to check
 * @returns {boolean} Whether it is an empty slate document
 */
export const isEmpty = val => val !== emptyString;

export const yupNotEmptyTest = {
  name: "AntonNotEmptyTest",
  test: isEmpty,
  message: "Dieses Feld darf nicht leer sein."
};
