import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button, Form, Input, Popover} from "reactstrap";
import PropTypes from "prop-types";

/**
 * Renders a popover to edit the hyperlink
 * @returns {Component} the react component
 */
export class HyperlinkEditPopover extends React.PureComponent {

  state = { url: '', target: null};

  /**
   * Handler that updates the state
   * @param {Event} event the event
   * @returns {void}
   */
  onInputChange = (event) => {
    this.setState({url: event.target.value});
  };

  /**
   * Changes the link
   * @param {Event} event the event
   * @returns {void}
   */
  changeLink = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {editor} = this.props;
    const newUrl = this.state.url;
    if(newUrl) {
      editor.moveStartToStartOfInline().moveEndToEndOfInline();
      if (editor.hasHyperlinks()) {
        editor.getHyperlinks().map(node => editor.setNodeByKey(node.key, {data: {...node.data.toJS(), url: newUrl}}));
      } else {
        editor.wrapLink(newUrl);
      }
      editor.moveToEnd();
    } else {
      this.removeLink();
    }
  };

  /**
   * Removes the link
   * @param {Event} event the event
   * @returns {void}
   */
  removeLink = (event) => {
    const {editor} = this.props;
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    editor.moveStartToStartOfInline().moveEndToEndOfInline();
    editor.unwrapLink();
    editor.moveToEnd();
  };

  componentWillMount() {
    const newUrl = this.props.url;
    this.setState({url: newUrl ? newUrl : ''});
  }

  /**
   * Called after the component updated
   * @param {Object} prevProps the previous props
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const oldUrl = prevProps.url;
    const newUrl = this.props.url;
    if(oldUrl !== newUrl) {
      this.setState({url: newUrl ? newUrl : ''});
    }
  }

  render = () => {
    const {url} = this.state;
    return (
        <Form inline>
          <Input type="url" name="url" id="url" value={url} onChange={this.onInputChange} placeholder="Füge eine Url ein (z.B. https://wit.app)" />
          <Button size="sm" color={"primary"} onClick={this.changeLink}><FontAwesomeIcon icon={["far","check"]} /></Button>
          <Button size="sm" color={"secondary"} onClick={this.removeLink}><FontAwesomeIcon icon={["far","unlink"]} /></Button>
        </Form>
    )
  }
}

HyperlinkEditPopover.propTypes = {
  editor: PropTypes.object,
  url: PropTypes.string,
};

export default HyperlinkEditPopover;
