import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {deserializeMark, renderMark, renderMarkButton} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { hotkey} from "../helpers/hotkey";
import {  toggleMark } from "../helpers/handlers";
import { hasMark } from "../helpers/content";

/**
 * Marks the selected text bold
 */
class BoldMarkButton extends AntonButton {
  name = "bold-mark";
  icon = <FontAwesomeIcon icon={["far","bold"]} />;
  onMouseDown = toggleMark("bold");
  tooltip = {
    target: "bold-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Text fettdrucken"
  };
}

/**
 * A component rendering bold text
 */
class BoldMark extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { attributes, children } = this.props;
    return <strong {...attributes}>{children}</strong>;
  };
}

/**
 * The bold Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Bold = (options) => Object.assign(
  hotkey('mod+b',toggleMark('bold')),
  renderMark('bold',BoldMark),
  deserializeMark("bold","b"),
  deserializeMark("bold","strong"),
  renderMarkButton("bold-mark",BoldMarkButton),
  {
    isActive: hasMark('bold'),
  }
);

export default Bold;
