import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button, Form, Input, Popover} from "reactstrap";
import PropTypes from "prop-types";

/**
 * Renders a popover to edit the hyperlink
 * @returns {Component} the react component
 */
export class VideoEditPopover extends React.PureComponent {

  state = { url: '', type: null};

  /**
   * Handler that updates the state
   * @param {Event} event the event
   * @returns {void}
   */
  onInputChange = (event) => {
    this.setState({url: event.target.value});
  };

  /**
   * onClick handler for the Video button
   * @param {Event} event the event
   * @returns {void}
   */
  changeVideo = (event) => {
    const {url} = this.state;
    event.preventDefault();
    event.stopPropagation();
    this.props.changeVideo(url);
  };

  /**
   * onClick handler for the removeVideo button
   * @param {Event} event the event
   * @returns {void}
   */
  removeVideo = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.removeVideo();
  };

  componentWillMount() {
    const newUrl = this.props.url;
    this.setState({url: newUrl ? newUrl : ''});
  }

  /**
   * Called after the component updated
   * @param {Object} prevProps the previous props
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const oldUrl = prevProps.url;
    const newUrl = this.props.url;
    if(oldUrl !== newUrl) {
      this.setState({url: newUrl ? newUrl : ''});
    }
  }

  render = () => {
    const {url} = this.state;
    return (
      <Form inline>
        <Input type="url" name="url" id="url" value={url} onChange={this.onInputChange} placeholder="Füge eine Youtube Url ein." />
        <Button size="sm" color={"primary"} onClick={this.changeVideo}><FontAwesomeIcon icon={["far","check"]} /></Button>
        <Button size="sm" color={"secondary"} onClick={this.removeVideo}><FontAwesomeIcon icon={["far","trash-alt"]} /></Button>
      </Form>
    )
  }
}

VideoEditPopover.propTypes = {
  editor: PropTypes.object,
  url: PropTypes.string,
  removeVideo: PropTypes.func,
  changeVideo: PropTypes.func
};

export default VideoEditPopover;
