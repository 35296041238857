import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {renderMarkButton, renderNode} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import {hotkey} from "../helpers/hotkey";
import {hasInline} from "../helpers/content";
import HyperlinkMark from "./HyperlinkMark";

/**
 * Toggles a hyperlink
 * @param {Editor} editor the editor instance
 * @returns {void}
 */
const toggleHyperlink = (editor) => {
  if(editor.hasHyperlinks()) {
    editor.unwrapLink();
  } else {
    editor.wrapLink('');
    editor.moveStartToStartOfInline().moveEndToEndOfInline();
  }
};

/**
 * Marks the selected text bold
 */
class HyperlinkMarkButton extends AntonButton {
  name = "hyperlink-mark";
  icon = <FontAwesomeIcon icon={["far","link"]} />;
  onMouseDown = toggleHyperlink;
  tooltip = {
    target: "hyperlink-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Text verlinken"
  }
}

const hotkeys = [
  {
    key: "mod+k",
    type: "hyperlink",
    action: toggleHyperlink,
    description: "Verlinkt den ausgewählten Text",
    example: "Das ist <a href='#'>verlinkter</strong> Text."
  }
];


/**
 * A change helper to standardize wrapping links.
 *
 * @param {editor} editor the editor instance
 * @param {string} href the url that is put inside the href attribute
 * @returns {void}
 */
const wrapLink = (editor,href) => {
  editor.wrapInline({
    type: 'hyperlink',
    data: { url: href },
  });
};

/**
 * A change helper to standardize unwrapping links.
 *
 * @param {Editor} editor the editor instance
 * @returns {void}
 */
const unwrapLink = (editor) => {
  editor.unwrapInline('hyperlink');
};

/**
 * Deserializes a link element
 * @param {Object} element The element to deserialize
 * @param {function} next A callback function to render the children
 * @returns {Object} The deserialized data
 */
const deserializeLink = (element, next) => {
  if (element.tagName.toLowerCase() === 'a') {
    return {
      object: 'inline',
      type: 'hyperlink',
      nodes: next(element.childNodes),
      data: {
        href: element.getAttribute('href'),
      },
    }
  }
};

/**
 * A query helper to get all the hyperlinks in the current selection
 *
 * @param {Editor} editor the editor instance
 * @returns {void}
 */
const getHyperlinks = (editor) => {
  const { value } = editor;
  return value.inlines.filter(inline => inline.type === 'hyperlink')
};

/**
 * The Hyperlink Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Hyperlink = (options) => Object.assign(
  renderNode("hyperlink", HyperlinkMark),
  renderMarkButton("hyperlink",HyperlinkMarkButton),
  hotkey('mod+k',toggleHyperlink),
  {
    deserialize: deserializeLink,
    isActive: hasInline('hyperlink'),
    commands: {
      wrapLink: wrapLink,
      unwrapLink: unwrapLink
    },
    queries: {
      hasHyperlinks: hasInline('hyperlink'),
      getHyperlinks: getHyperlinks
    }
  }
);

export default Hyperlink;
