import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Alert, ButtonGroup, Form, Popover, PopoverBody} from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";

import { VALID_IMAGE_EXTENSIONS, VALID_IMAGE_TYPES, validateImage } from "../../../utilities/validation";
import Button from "../../Button";

const Preview = styled.img`
	height: 100%;
	width: auto;
	border-radius: 2px;
	margin-right: 0.5rem;
`;

const LAYOUT_OPTIONS = [
  { key: "align-left", icon: ["far","align-left"] },
  { key: "align-center", icon: ["far","align-center"] },
  { key: "align-right", icon: ["far","align-right"] },
  { key: "full-width", icon: ["far","arrows-h"] }
];

/**
 * Renders a popover to edit the image
 * @returns {Component} the react component
 */
export class ImageEditPopover extends React.Component {

  state = { file: null, src: null, uploading: false, error: null };

  /**
   * Method called after the image has been selected
   * @param {Event} e The change event
   * @returns {void}
   */
  onUpload = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ uploading: true });
    const { onUpload, changeImage } = this.props;
    onUpload(this.state.file)
      .then((image) => {
        this.setState({ uploading: false });
        changeImage(image);
      })
      .catch(error =>
        this.setState({ error: error.errors, uploading: false })
      );
  };

  /**
   * Method called after the image has been selected
   * @param {Event} e The change event
   * @returns {void}
   */
  onChange = e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const errors = [] || validateImage(file);

    this.setState({
      file,
      errors,
      src: errors.length > 0 ? null : this.state.src
    });

    if (errors.length > 0) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => this.setState({ src: reader.result }),
      false
    );

    reader.readAsDataURL(file);
  };

  /**
   * onClick handler for the changeLayout button
   * @param {Event} event the event
   * @param {string} layout the new layout
   * @returns {void}
   */
  changeLayout = (event,layout) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.changeLayout(layout);
  };

  /**
   * onClick handler for the removeImage button
   * @param {Event} event the event
   * @returns {void}
   */
  removeImage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.removeImage();
  };

  render = () => {
    const {target, image, onInputChange, isOpen, layout} = this.props;
    const { file, src, uploading, error } = this.state;
    return (
      target ? (
        <Popover placement={"bottom"} isOpen={isOpen} target={target} toggle={() => null}>
          <PopoverBody>
          <Form inline>
            {!image ?
              <React.Fragment>
              <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="anton-upload-image"
                accept={
                  VALID_IMAGE_EXTENSIONS.join(",") +
                  "," +
                  VALID_IMAGE_TYPES.join(",")
                }
                onChange={this.onChange}
              />
              <label
                className="custom-file-label justify-content-start"
                htmlFor="anton-upload-image"
                data-browse-text="Wähle ein Bild"
              >
                {src && <Preview src={src} />}
                {file ? file.name : "Kein Bild ausgewählt"}
              </label>
            </div>
            <p className="text-muted small mt-2">
              Erlaubt sind Bilder in den Formaten{" "}
              <strong>.png, .jpg, .gif und .svg</strong> mit einer maximalen
              Grösse von <strong>2 MB</strong>.
            </p>
            {error &&
            error.file &&
            error.file.map((error, index) =>
              <Alert key={index} color="danger" className="mt-2">
                {error}
              </Alert>
            )}
            <Button size="sm" className={"mx-1"} color={"primary"} onClick={this.onUpload} loading={uploading} disabled={!file}>Hochladen</Button>
          </React.Fragment>
            :
              <ButtonGroup size={"sm"} className={"mr-1"}>
              {LAYOUT_OPTIONS.map(l => (
                <Button key={l.key } onClick={(e) => this.changeLayout(e,l.key)} active={l.key === layout}><FontAwesomeIcon icon={l.icon}/></Button>
              ))}
              </ButtonGroup>
            }
          <Button size="sm" color={"secondary"} onClick={this.removeImage}><FontAwesomeIcon icon={["far","trash-alt"]} /></Button>
        </Form>
          </PopoverBody>
      </Popover>) : null
    )
  }
}

ImageEditPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  image: PropTypes.object,
  layout: PropTypes.string,
  changeImage: PropTypes.func.isRequired,
  changeLayout: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired
};

export default ImageEditPopover;
