import React from "react";
import styled from "styled-components";
import { Popover } from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import getYouTubeID from 'get-youtube-id';

import { colors } from "../../../utilities/style";
import VideoEditPopover from "./VideoEditPopover";
import VideoEmbed from "../../VideoEmbed";

const VideoWrapper = styled.div`
  background: ${colors.grey_1};
  color: ${colors.fontLight};
  border-radius: 5px;
  display: block;
  position: relative;
  
  ${({url}) => (url ? `background: transparent; color: ${colors.font};` : '')}
  
  ${({serializer, isFocused, isSelected}) => {
  if(!serializer && (isFocused || isSelected)) {
    return (`background: ${colors.green_1}; cursor: pointer;`)
  }
}}
  &&:hover {
    ${({serializer}) => (serializer ? '' : `background: ${colors.green_1}; cursor: pointer;`)}
  }
`;

const Mask = styled.div`
  display: ${(isFocused) => (!isFocused ? 'none' : 'block')};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
 `;


/**
 * Renders a video
 * @returns {Component} the react component
 */
class VideoBlock extends React.PureComponent {

  ref = null;

  constructor(props) {
    super(props);
  }

  /**
   * Sets a reference to the element
   * @param {Element} element the element that should be set as reference
   * @returns {void}
   */
  setRef = (element) => this.ref = element;

  removeVideo = () => {
    const { node, editor } = this.props;
    editor.setBlocks('paragraph');
  };

  /**
   * Change the url of the video
   * @param {string} url the url
   * @returns {void}
   */
  changeVideo = (url) => {
    const { node, editor } = this.props;
    const type = "youtube"; // todo: code type detection
    if(url) {
      editor.setNodeByKey(node.key, { data: { ...node.data.toJS(), url, type }});
      editor.moveForward();
    }
  };


  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, attributes, node, isSelected, isFocused, serializer, editor } = this.props;

    const url = node.data.get("url");
    const type = node.data.get("type");
    const id = getYouTubeID(url);
    let embedUrl = id ? `https://www.youtube.com/embed/${id}?showinfo=0&modestbranding=1&controls=2` : null;
    
    return (
      <React.Fragment>
        {serializer && embedUrl &&
        <VideoEmbed url={embedUrl} {...attributes} />
        }
        {!serializer &&
        <VideoWrapper innerRef={this.setRef} className={"my-2 p-2"} serializer={serializer} id={id}
                      isFocused={isFocused} isSelected={isSelected}>
          <Mask isFocused={isFocused}/>
          {embedUrl && <VideoEmbed url={embedUrl} {...attributes} />}
          {!embedUrl &&
          <span><FontAwesomeIcon icon={["far","film"]} size={"lg"} className={"mr-2"}/>Füge ein Youtube Video ein.</span>
          }
          {url && !id && <p className={"text-danger mb-0"}>Es muss eine gültige Youtube URL eingegeben werden.</p>}
        </VideoWrapper>
        }
        {!serializer && this.ref && <Popover target={this.ref} placement={"bottom"} isOpen={(isFocused)}>
          <VideoEditPopover
            changeVideo={this.changeVideo}
            removeVideo={this.removeVideo}
            editor={editor}
            url={url}/>
        </Popover>
        }
      </React.Fragment>
    );
  };
}

export default VideoBlock;
