import { css } from "styled-components";

export const colors = {
  primary: "#23CD93",
  background: "#f5f5f5",
  backgroundContrast: "#ffffff",
  font: "#080921",
  fontDark: "#444444",
  fontLight: "#888888",
  borderLight: "#888888",
  success: "#23CD93",
  info: "#3498db",
  warning: "#F0BF41",
  danger: "#E62749",
  grey_light: "#E0E0E0",
  grey_medium: "#BDBDBD",
  grey_regular: "#828282",
  grey_dark: "#4F4F4F",
  bg_light: "#F5F7FB",
  grey_1: "#DDE0E6",
  grey_2: "#79849B",
  grey_3: "#484961",
  black: "#080921",
  green_1: "#C8F2E4",
  green_2: "#5ADAAE",
  green_3: "#23CD93",
  green_4: "#1A9A6E"
};

export const shadows = {
  y: "0px 2px 10px rgba(0, 0, 0, 0.05)",
  outline: "0 0 0 0.2rem rgba(35, 205, 147, 0.5)"
};

export const media = {
  minSmall: (...args) => css`
    @media (min-width: 576px) {
      ${css(...args)};
    }
  `,
  maxSmall: (...args) => css`
    @media (max-width: 575px) {
      ${css(...args)};
    }
  `,
  minMedium: (...args) => css`
    @media (min-width: 768px) {
      ${css(...args)};
    }
  `,
  maxMedium: (...args) => css`
    @media (max-width: 767px) {
      ${css(...args)};
    }
  `,
  minLarge: (...args) => css`
    @media (min-width: 992px) {
      ${css(...args)};
    }
  `,
  maxLarge: (...args) => css`
    @media (max-width: 991px) {
      ${css(...args)};
    }
  `,
  minXLarge: (...args) => css`
    @media (min-width: 1200px) {
      ${css(...args)};
    }
  `,
  maxXLarge: (...args) => css`
    @media (max-width: 1199px) {
      ${css(...args)};
    }
  `
};
