import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {deserializeMark, renderMark, renderMarkButton} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { hotkey} from "../helpers/hotkey";
import { toggleMark } from "../helpers/handlers";
import { hasMark } from "../helpers/content";

/**
 * Underlines the selected text
 */
class UnderlineMarkButton extends AntonButton {
  name = "underline-mark";
  icon = <FontAwesomeIcon icon={["far","underline"]} />;
  onMouseDown = toggleMark("underline");
  tooltip = {
    target: "underline-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Text unterstreichen"
  };
}

const hotkeys = [
  {
    key: "mod+u",
    type: "underline",
    action: toggleMark("underline"),
    description: "Unterstreicht den ausgewählten Text",
    example: "Das ist <u>unterstrichener</u> Text."
  }
];

/**
 * A component rendering underline text
 */
class UnderlineMark extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { attributes, children } = this.props;
    return <u {...attributes}>{children}</u>;
  };
}

/**
 * The Underline Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const Underline = (options) => Object.assign(
  hotkey('mod+u',toggleMark('underline')),
  renderMark('underline',UnderlineMark),
  deserializeMark("underline","u"),
  renderMarkButton("underline-mark",UnderlineMarkButton),
  {
    isActive: hasMark('underline'),
  }
);

export default Underline;
