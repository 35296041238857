import {hasBlock, hasInline} from "./content";

const DEFAULT_NODE = "paragraph";

/**
 * Toggles the current mark
 * @param {string} type The mark type
 * @returns {void}
 */
export const addMark = type => (editor) => {
	editor.addMark(type);
};

/**
 * Toggles the current mark
 * @param {string} type The mark type
 * @returns {void}
 */
export const removeMark = type => (editor) => {
	editor.removeMark(type);
};

/**
 * Toggles the current mark
 * @param {string} type The mark type
 * @param {Editor} editor the slate editor
 * @returns {void}
 */
export const toggleMark = type => (editor) => {
	editor.toggleMark(type);
};


/**
 * When a block button is clicked, toggle the block type.
 * @param {string} type The block type
 * @returns {void}
 */
export const toggleBlock = type => (editor) => {
	const { value } = editor;
	const { document } = value;

	// Handle everything but list buttons.
	if (type !== "bulleted-list" && type !== "numbered-list") {
		const isActive = hasBlock(type)(editor);
		const isList = hasBlock("list-item")(editor);

		if (isList) {
			editor
				.setBlocks(isActive ? DEFAULT_NODE : type)
				.unwrapBlock("bulleted-list")
				.unwrapBlock("numbered-list");
		} else {
			editor.setBlocks(isActive ? DEFAULT_NODE : type);
		}
	} else {
		// Handle the extra wrapping required for list buttons.
		const isList = hasBlock("list-item")(editor);
		const isType = value.blocks.some(block => {
			return !!document.getClosest(block.key, parent => parent.type === type);
		});

		if (isList && isType) {
			editor
				.setBlocks(DEFAULT_NODE)
				.unwrapBlock("bulleted-list")
				.unwrapBlock("numbered-list");
		} else if (isList) {
			editor
				.unwrapBlock(
					type === "bulleted-list" ? "numbered-list" : "bulleted-list"
				)
				.wrapBlock(type);
		} else {
			editor.setBlocks("list-item").wrapBlock(type);
		}
	}
};
