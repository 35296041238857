import SoftBreak from "slate-soft-break";

import ExtendNode from "../features/extend-node";
import RemoveType from "../features/remove-type";
import Paragraph from "../features/paragraph";
import Bold from "../features/bold";
import Italic from "../features/italic";
import Latex from "../features/latex";
import Underline from "../features/underline";
import H1 from "../features/heading-one";
import H2 from "../features/heading-two";
import H3 from "../features/heading-three";
import Quote from "../features/quote";
import Code from "../features/code";
import ListItem from "../features/list-item";
import BulletedList from "../features/bulleted-list";
import NumberedList from "../features/numbered-list";
import Image from "../features/image";
import Hyperlink from "../features/hyperlink";
import NoEmpty from "./no-empty";
import Video from "../features/video";

export const plugins = [
  NoEmpty("paragraph"),
  SoftBreak({
    shift: true,
    onlyIn: ["code", "quote"]
  }),
  Latex(),
  Image(),
  Bold(),
  Italic(),
  Underline(),
  H1(),
  H2(),
  H3(),
  Quote(),
  Code(),
  Hyperlink(),
  ListItem(),
  BulletedList(),
  NumberedList(),
  Paragraph(),
  ExtendNode(),
  RemoveType(),
  Video(),
];

/* prevent empty document (e.g. after image deletion) */
plugins.push();

