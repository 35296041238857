import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from "styled-components";

import {renderBlockButton, renderMarkButton, renderNode} from "../helpers/render";
import AntonButton from "../helpers/AntonButton";
import { toggleBlock } from "../helpers/handlers";
import { hasBlock } from "../helpers/content";

/**
 * Converts the selected text to a bulleted list
 */
class BulletedListMarkButton extends AntonButton {
  name = "bulleted-list-mark";
  icon = <FontAwesomeIcon icon={["far","list-ul"]} />;
  onMouseDown = toggleBlock("bulleted-list");
  tooltip = {
    target: "bulleted-list-mark",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Zu ungeordneter Liste konvertieren"
  }
}

/**
 * Inserts a bulleted list
 */
class BulletedListBlockButton extends AntonButton {
  name = "bulleted-list-block";
  icon = <FontAwesomeIcon icon={["far","list-ul"]} />;
  onMouseDown = toggleBlock("bulleted-list");
  tooltip = {
    target: "bulleted-list-block",
    delay: { show: 0, hide: 0 },
    placement: "top",
    children: "Ungeordnete Liste einfügen"
  }
}

const StyledUl = styled.ul`
  padding-left: 1.5rem;
`;

/**
 * A component rendering a bulleted list
 */
class BulletedListBlock extends React.PureComponent {
  /**
   * Renders the component
   * @returns {Node} The component
   */
  render = () => {
    const { children, attributes } = this.props;
    return <StyledUl {...attributes}>{children}</StyledUl>;
  };
}

/**
 * The Bulleted List Plugin
 * @param {Object} options additional options for the plugin
 * @returns {Object} the plugin
 */
const BulletedList = (options) => Object.assign(
  renderNode("bulleted-list", BulletedListBlock),
  renderMarkButton("bulleted-list",BulletedListMarkButton),
  renderBlockButton("bulleted-list",BulletedListBlockButton),
  {
    isActive: hasBlock("bulleted-list"),
  }
);

export default BulletedList;
